import {HttpErrorResponse, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http';
import {catchError, throwError} from "rxjs";
import {inject, Injector} from "@angular/core";
import {Page, PageNavigatorService} from "#core/services/page-navigator.service";
import {Router} from "@angular/router";

export class HttpBadRequestError extends Error {
  constructor(message: string) {
    super('Bad request error:\n' + message);
  }
}

export class HttpUnauthorizedError extends Error {
  constructor(message: string) {
    super('Unauthorized error:\n' + message);
  }
}

export class HttpForbiddenError extends Error {
  constructor(message: string) {
    super('Forbidden error:\n' + message);
  }
}

export class HttpNotFoundError extends Error {
  constructor(message: string) {
    super('Not found error:\n' + message);
  }
}


export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const pn = inject(PageNavigatorService);
  return next(req).pipe(
    catchError((error) => {
      /**
       * {link} https://developer.mozilla.org/ja/docs/Web/HTTP/Status
       * */
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case HttpStatusCode.BadRequest:
            // Handle 400 error
            console.error('Bad request error', error);
            return throwError(() => new HttpBadRequestError(error.message));
          case HttpStatusCode.Unauthorized:
            // Handle 401 error
            console.error('Unauthorized error', error);
            pn.navigateToPage(Page.Top)
            return throwError(() => new HttpUnauthorizedError(error.message));
          case HttpStatusCode.Forbidden:
            // Handle 403 error
            console.error('Forbidden error', error);
            return throwError(() => new HttpForbiddenError(error.message));
          case HttpStatusCode.NotFound:
            // Handle 403 error
            console.error('Not found error', error);
            return throwError(() => new HttpNotFoundError(error.message));
          case HttpStatusCode.InternalServerError:
            // Handle 500 error
            console.error('Internal server error', error)
            alert('予期せぬエラーが発生しました。しばらくしてから再度お試しください。');
            break;
        }
      }
      console.error('Unknown error', error);
      return throwError(() => error);
    })
  );
};
